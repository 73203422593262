* {
    margin: 0px;
}

@media (min-width: 1921px) {
    html {
        font-size: 0.8329vw;
    }    
}

body {
    margin: 0px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

code {
    margin: 0px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

@font-face{
    font-family: mainTitleFont;
    src:url(../assets/lemon-milk-font/LemonMilkMedium-mLZYV.otf);
}
