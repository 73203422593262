/* .tagTray{
    background:transparent;   
    overflow-x: auto;
} */

.tagTrayInputPanel{
    /* padding-right: var(--bs-gutter-x,.75rem);
    padding-left: var(--bs-gutter-x,.75rem); */
    margin-bottom: 0.5rem;
}

textarea{
    /* height: 3rem;       */
    max-height: 3rem;
    min-height: 3rem;
    resize:none;
    border-radius: 15%;
    border: 0.625rem solid yellow;
    background: white;
}

.transparentContainer {
    background:transparent;
}

.plusSign{
    background:transparent;
    fill: black;
    width:1rem;
    height:1rem;
    
}

.inputPanelItems{
    background-color: transparent;
}

.inputPanelButton{
    background:#b2b2b2;
}

.inputPanelInput{
    background-color:transparent;
    color:#b2b2b2;
    border-width: 0.0625rem;

}

.inputPanelLabels{
    color:#b2b2b2;
    margin-bottom: 0.25rem;
}

select{
    background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23b2b2b2' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") !important;
    color:red;
}



.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #b2b2b2;
    opacity: 1; /* Firefox */
}

.form-control:focus{
    background-color:transparent;
    color:#b2b2b2;
}

select:focus{
    background-color:transparent;
}

#textAreaInputPanel {
    border-width: 0.0625rem;
}


select, input {
    overflow:hidden;
    text-overflow: ellipsis;
}
