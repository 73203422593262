/* #player {
    border: none;
} */

#controlsRow {
    margin-bottom: 2em;
}

button {
    color:white;
}

.playerButtons {
    /* margin-right: 2em; */
    /* margin-left:1rem;
    margin-right:1rem; */
    background:transparent;
    fill:white;
}

#playerPlay{
    /* margin:0.5rem; */
}

.playerMobileIcons{
    /* margin-left:0.375rem;
    margin-right:0.375rem; */
    margin:0px;
}

.playerSideButtons{
    /* background:#b2b2b2; */
    background:transparent;
    color: #b2b2b2;
    fill: #b2b2b2;
    border-width: 0.0625rem;
    /* font-weight: 600; */
}

/* #playerLoopButton {
    box-sizing: border-box;
    width: 3rem;
    height: 3rem;
} */
#playerLoopButton.btn {
    box-sizing: border-box;
    width: 9rem;
    height: 3rem;
 }

#playerSaveButton.btn {
    box-sizing: border-box;
    width: 9rem;
    height: 3rem;
}

.playerSideButtons:hover{
    /* background:#b2b2b2; */
    background:transparent;
    color: white;
    fill: white;
    border-color: white;
    /* font-weight: 600; */
}

#timeLabelLeft{
  /* color: #acacac; */
  /* font-size: 0.90rem; */
  width: min-content;
  text-align: center;
  background: transparent;
  margin-bottom: 0.20rem;
  padding-left:0;
  padding-right: 0;
}

#timeLabelRight{
  /* color: #acacac; */
  /* font-size: 0.90rem; */
  width: min-content;
  text-align: center;
  background: transparent;
  margin-bottom: 0.20rem;
  padding-left:0;
  padding-right:0;
}

#rangeRow{
  display: flex;
  align-items: center;
  justify-content: center; /* center the items horizontally */
  /* width: 100%; */
}

/* .rc-slider{
  width:auto;
} */

.rangeContainer {
  flex-grow: 1; 
  width:auto;
}


@media (max-width:768px) {
    #playerSaveButton, #playerLoopButton {
        display:none;
    }
}
.playerLeftButtonWrapper {
    background: transparent;
    justify-content: space-between;
    width:20%;
}

.playerMiddleButtonWrapper {
    background: transparent;
    width:60%;
    /* justify-content:space-evenly; */
}

.playerRightButtonWrapper {
    background: transparent;
    /* justify-content: space-between; */
    width:20%;
}

.trackLooped{
    border-width: 0.1875rem;
    border-color: white;
    /* background:#b2b2b2; */
    color: white;
    font-weight:bold;
}

.trackIconLooped {
    fill: #1ab26b;
}

#playerNextTrackButtonLeft{
    transform:rotate(180deg);
}
/* 
#playerStop, #playerPlay{
    display:flex;
    align-self: center;
} */

#save {
    /* margin-left: 2em; */
}

.playerItems{
    /* background-color: #181818; */
    /* background-color: #303030; */
    background-color: transparent;
}

.controlsWrapper{
    width:20rem;
    max-width: 100%;
    justify-content:space-between;
    align-items:center;
}

.transparentContainer {
    background:transparent;
}

/* @media (max-width:992px) {
    .controlsWrapper{
        width:60%;        
    }
}

@media (max-width:768px) {
    .controlsWrapper{
        width:100%;        
    }
} */

#controls{
    justify-content: center;
}

.rc-slider-tooltip, .rc-slider-tooltip-arrow{
    background: transparent;
}


@media (min-width: 1200px) {
    .rc-slider-handle {
        width: 0.875rem;
        height: 0.875rem;
        margin-top: -0.3125rem;
    }
    .rc-slider-rail {
        height: 0.25rem;        
    }
    /* .rc-slider-tooltip{
        height: 1.5rem;    
        
    } */

    /* .rc-slider-tooltip-arrow {
        bottom: 0.25rem;
        left: 50%;
        margin-left: -0.25rem;
        border-width: 0.25rem 0.25rem 0;
        border-top-color: #6c6c6c;
    } */



    
    .rc-slider-tooltip-inner {
        font-size: 0.75rem;
        padding: 0.375rem 0.125rem;
        min-width: 1.5rem;
        height: 1.5rem;        
        line-height: 1;
        color: #fff;
        text-align: center;
        text-decoration: none;
        background-color: #6c6c6c;
        border-radius: 0.375rem;
        box-shadow: 0 0 0.25rem #d9d9d9;
    }
    .rc-slider-tooltip-placement-top {
        padding: 0.25rem 0 0.5rem 0;
    }

    .rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
        bottom: 0.25rem;
        left: 50%;
        margin-left: -0.25rem;
        border-width: 0.25rem 0.25rem 0;
        border-top-color: #6c6c6c;
    }
}


/* adjusts rc-slider module styling to fit app */
.rc-slider {
    position: relative;
    height: 14px;
    padding: 5px 0;
    width: 100%;
    border-radius: 6px;
    touch-action: none;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .rc-slider * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .rc-slider-rail {
    position: absolute;
    width: 100%;
    background-color: #e9e9e9;
    height: 4px;
    border-radius: 6px;
  }
  .rc-slider-track {
    position: absolute;
    left: 0;
    height: 4px;
    border-radius: 6px;
    background-color: #abe2fb;
  }
  .rc-slider-handle {
    position: absolute;
    width: 14px;
    height: 14px;
    cursor: pointer;
    cursor: -webkit-grab;
    margin-top: -5px;
    cursor: grab;
    border-radius: 50%;
    border: solid 2px #96dbfa;
    background-color: #fff;
    touch-action: pan-x;
  }
  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    border-color: #57c5f7;
    box-shadow: 0 0 0 5px #96dbfa;
  }
  .rc-slider-handle:focus {
    outline: none;
  }
  .rc-slider-handle-click-focused:focus {
    border-color: #96dbfa;
    box-shadow: unset;
  }
  .rc-slider-handle:hover {
    border-color: #57c5f7;
  }
  .rc-slider-handle:active {
    border-color: #57c5f7;
    box-shadow: 0 0 5px #57c5f7;
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }
  .rc-slider-mark {
    position: absolute;
    top: 18px;
    left: 0;
    width: 100%;
    font-size: 12px;
  }
  .rc-slider-mark-text {
    position: absolute;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    color: #999;
  }
  .rc-slider-mark-text-active {
    color: #666;
  }
  .rc-slider-step {
    position: absolute;
    width: 100%;
    height: 4px;
    background: transparent;
  }
  .rc-slider-dot {
    position: absolute;
    bottom: -2px;
    margin-left: -4px;
    width: 8px;
    height: 8px;
    border: 2px solid #e9e9e9;
    background-color: #fff;
    cursor: pointer;
    border-radius: 50%;
    vertical-align: middle;
  }
  .rc-slider-dot-active {
    border-color: #96dbfa;
  }
  .rc-slider-dot-reverse {
    margin-right: -4px;
  }
  .rc-slider-disabled {
    background-color: #e9e9e9;
  }
  .rc-slider-disabled .rc-slider-track {
    background-color: #ccc;
  }
  .rc-slider-disabled .rc-slider-handle,
  .rc-slider-disabled .rc-slider-dot {
    border-color: #ccc;
    box-shadow: none;
    background-color: #fff;
    cursor: not-allowed;
  }
  .rc-slider-disabled .rc-slider-mark-text,
  .rc-slider-disabled .rc-slider-dot {
    cursor: not-allowed !important;
  }
  .rc-slider-vertical {
    width: 14px;
    height: 100%;
    padding: 0 5px;
  }
  .rc-slider-vertical .rc-slider-rail {
    height: 100%;
    width: 4px;
  }
  .rc-slider-vertical .rc-slider-track {
    left: 5px;
    bottom: 0;
    width: 4px;
  }
  .rc-slider-vertical .rc-slider-handle {
    margin-left: -5px;
    touch-action: pan-y;
  }
  .rc-slider-vertical .rc-slider-mark {
    top: 0;
    left: 18px;
    height: 100%;
  }
  .rc-slider-vertical .rc-slider-step {
    height: 100%;
    width: 4px;
  }
  .rc-slider-vertical .rc-slider-dot {
    left: 2px;
    margin-bottom: -4px;
  }
  .rc-slider-vertical .rc-slider-dot:first-child {
    margin-bottom: -4px;
  }
  .rc-slider-vertical .rc-slider-dot:last-child {
    margin-bottom: -4px;
  }
  .rc-slider-tooltip-zoom-down-enter,
  .rc-slider-tooltip-zoom-down-appear {
    animation-duration: 0.3s;
    animation-fill-mode: both;
    display: block !important;
    animation-play-state: paused;
  }
  .rc-slider-tooltip-zoom-down-leave {
    animation-duration: 0.3s;
    animation-fill-mode: both;
    display: block !important;
    animation-play-state: paused;
  }
  .rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
  .rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
    animation-name: rcSliderTooltipZoomDownIn;
    animation-play-state: running;
  }
  .rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
    animation-name: rcSliderTooltipZoomDownOut;
    animation-play-state: running;
  }
  .rc-slider-tooltip-zoom-down-enter,
  .rc-slider-tooltip-zoom-down-appear {
    transform: scale(0, 0);
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  }
  .rc-slider-tooltip-zoom-down-leave {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  }
  @keyframes rcSliderTooltipZoomDownIn {
    0% {
      opacity: 0;
      transform-origin: 50% 100%;
      transform: scale(0, 0);
    }
    100% {
      transform-origin: 50% 100%;
      transform: scale(1, 1);
    }
  }
  @keyframes rcSliderTooltipZoomDownOut {
    0% {
      transform-origin: 50% 100%;
      transform: scale(1, 1);
    }
    100% {
      opacity: 0;
      transform-origin: 50% 100%;
      transform: scale(0, 0);
    }
  }
  .rc-slider-tooltip {
    position: absolute;
    left: -9999px;
    top: -9999px;
    visibility: visible;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .rc-slider-tooltip * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .rc-slider-tooltip-hidden {
    display: none;
  }
  .rc-slider-tooltip-placement-top {
    padding: 4px 0 8px 0;
  }
  .rc-slider-tooltip-inner {
    padding: 6px 2px;
    min-width: 24px;
    height: 24px;
    font-size: 12px;
    line-height: 1;
    color: #fff;
    text-align: center;
    text-decoration: none;
    background-color: #6c6c6c;
    border-radius: 6px;
    box-shadow: 0 0 4px #d9d9d9;
  }
  .rc-slider-tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }
  .rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
    bottom: 4px;
    left: 50%;
    margin-left: -4px;
    border-width: 4px 4px 0;
    border-top-color: #6c6c6c;
  }


  

