*:not(.swal2-popup > *) {
    background-color: black;
}

#loginVisualizer
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
    /* z-index: 0; */
    /* background-color: transparent; */
    opacity: 0.1;
}

.login {
    display: flex;
    align-items:center;
    position:relative;
    /* place-items: center; */
    height: 100vh;   
    background-color: transparent;
    flex-direction: column;
    /* z-index: 5; */
    /* flex-shrink: 5; */
    overflow: hidden;
}



.login > img {     
    width: clamp(4.85rem, 20%, 20%);
    height: auto;    
    margin-top: 5vh;
    /* margin-left:80vw; */
    alt: unset;
    background-color: transparent;
    /* align-self: center; */
    
}



#projectName{
    /* display:flex; */
    font-family: mainTitleFont;
    font-size: clamp(3.8rem, 15vw, 15vw);
    color:#1ab26b;
    background-color: transparent;
    /* margin-bottom: 15vh; */
    /* font-size-adjust: ; */
}

.loginButtonContainer{
    /* align-items: flex-end; */
    width:100%;
    display:inline-flex;
    justify-content: center;    
    flex-wrap: wrap;
    column-gap: 15rem;
    /* flex-grow: 1;
    margin-bottom: 10rem; */

}

.leftSection {
    /* margin-right:0;     */
    
    width:16rem;
    max-width:50%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background: transparent;
    
}

.rightSection {
    width:16rem;
    max-width:50%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background: transparent;
}



@media (max-width:992px) {
    .loginButtonContainer {
        margin-top: 20vh;
    } 

    .rightSection {
        /* margin-left:0;     */
        margin-top: 10vh;
    }
}

.loginButtonContainerItems{
    /* flex-wrap: wrap; */
    background-color: transparent;
    /* gap: 5; */
    /* padding-top: 0.5rem;
    padding-bottom: 0.5rem; */
}

.loginButtonSpan{
    /* display:block; */
    background-color: transparent;
    font-style: italic;
    font-size: 1rem;
    font-weight:1000;
    text-align:center;
    /* max-height: 15vh; */
    text-transform: uppercase;
    padding-left:4rem;
    padding-right:4rem;
    line-height: 1.25rem;
    width: 24rem;
    color:#b2b2b2;

}

#textBlockLeft, #textBlockRight{
    background-color: transparent;
}


.transparentContainer {
    background: transparent;
}


#textBlockLeft{
    /* margin-right: 2vw; */
    display:flex;
}


#textBlockRight{
    /* margin-left: 2vw; */
    display:flex;
}

/* TODO: enlarge and add white border on hover */
.loginButtonSpan2 {    
    font-family: Arial, Helvetica, sans-serif;
    /* width: 100%; */
    font-size: 1rem;
    /* max-width: 40%; */
    padding: 0.875rem 2rem;
    /* background-color: #1db954; */
    background-color: #1ab26b;
    border-radius: 31.25rem;  
    font-weight:700;
    text-transform: uppercase;   
    color: black;
    text-align:center;
    width:16rem;
    height:3rem;
    /* max-height: 5rem; */ 
    display:flex;
    justify-content: center;
    align-items: center;
    
       
}

.loginButtonSpanWrapper {
    width:17rem;
    height:3.25rem;
    display:flex;
    justify-self: center;
    
    align-items: center;
    background: transparent;
}

.loginButtonSpan2:hover {
    width:17rem;
    height:3.25rem;
    font-size: 1.05rem;
}

#loginClickLeft, #loginClickRight{
    text-decoration: none;
    margin-bottom: 0.75rem; 
}



.colItems{
    align-content: center;
}

.loginUnderlines{
    background-color:transparent;
    color:#1ab26b;
}