/*!
 * Bootstrap 8k v1.0 
 * based on Bootstrap v4.1.1 (https://getbootstrap.com/)
 * 
 * Copyright 2011-2018 Mike van  der Velde
 * Licensed under MIT ()
 */
:root {
	--breakpoint-2k: 1900px;
	--breakpoint-3k: 2800px;
	--breakpoint-4k: 3800px;
	--breakpoint-6k: 5700px;
	--breakpoint-8k: 7600px;
}

@media (min-width: 1900px) {
	.container {
		max-width: 1860px;
	}
}

@media (min-width: 2800px) {
	.container {
		max-width: 2790px;
	}
}

@media (min-width: 3800px) {
	.container {
		max-width: 3720px;
	}
}

@media (min-width: 5700px) {
	.container {
		max-width: 5580px;
	}
}

@media (min-width: 7600px) {
	.container {
		max-width: 7440px;
	}
}

.col-2k-1, .col-2k-2, .col-2k-3, .col-2k-4, .col-2k-5, .col-2k-6, .col-2k-7, .col-2k-8, .col-2k-9, .col-2k-10, .col-2k-11, .col-2k-12, .col-2k,
.col-2k-auto, .col-3k-1, .col-3k-2, .col-3k-3, .col-3k-4, .col-3k-5, .col-3k-6, .col-3k-7, .col-3k-8, .col-3k-9, .col-3k-10, .col-3k-11,
.col-3k-12, .col-3k, .col-3k-auto, .col-4k-1, .col-4k-2, .col-4k-3, .col-4k-4, .col-4k-5, .col-4k-6, .col-4k-7, .col-4k-8, .col-4k-9, .col-4k-10,
.col-4k-11, .col-4k-12, .col-4k, .col-4k-auto, .col-6k-1, .col-6k-2, .col-6k-3, .col-6k-4, .col-6k-5, .col-6k-6, .col-6k-7, .col-6k-8, .col-6k-9,
.col-6k-10, .col-6k-11, .col-6k-12, .col-6k, .col-6k-auto, .col-8k-1, .col-8k-2, .col-8k-3, .col-8k-4, .col-8k-5, .col-8k-6, .col-8k-7, .col-8k-8,
.col-8k-9, .col-8k-10, .col-8k-11, .col-8k-12, .col-8k, .col-8k-auto {
	position: relative;
	width: 100%;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
}


@media (min-width: 1900px) {
	.col-2k {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-ms-flex-positive: 1;
		flex-grow: 1;
		max-width: 100%;
	}

	.col-2k-auto {
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: none;
	}

	.col-2k-1 {
		-ms-flex: 0 0 8.333333%;
		flex: 0 0 8.333333%;
		max-width: 8.333333%;
	}

	.col-2k-2 {
		-ms-flex: 0 0 16.666667%;
		flex: 0 0 16.666667%;
		max-width: 16.666667%;
	}

	.col-2k-3 {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}

	.col-2k-4 {
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}

	.col-2k-5 {
		-ms-flex: 0 0 41.666667%;
		flex: 0 0 41.666667%;
		max-width: 41.666667%;
	}

	.col-2k-6 {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}

	.col-2k-7 {
		-ms-flex: 0 0 58.333333%;
		flex: 0 0 58.333333%;
		max-width: 58.333333%;
	}

	.col-2k-8 {
		-ms-flex: 0 0 66.666667%;
		flex: 0 0 66.666667%;
		max-width: 66.666667%;
	}

	.col-2k-9 {
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%;
	}

	.col-2k-10 {
		-ms-flex: 0 0 83.333333%;
		flex: 0 0 83.333333%;
		max-width: 83.333333%;
	}

	.col-2k-11 {
		-ms-flex: 0 0 91.666667%;
		flex: 0 0 91.666667%;
		max-width: 91.666667%;
	}

	.col-2k-12 {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}

	.order-2k-first {
		-ms-flex-order: -1;
		order: -1;
	}

	.order-2k-last {
		-ms-flex-order: 13;
		order: 13;
	}

	.order-2k-0 {
		-ms-flex-order: 0;
		order: 0;
	}

	.order-2k-1 {
		-ms-flex-order: 1;
		order: 1;
	}

	.order-2k-2 {
		-ms-flex-order: 2;
		order: 2;
	}

	.order-2k-3 {
		-ms-flex-order: 3;
		order: 3;
	}

	.order-2k-4 {
		-ms-flex-order: 4;
		order: 4;
	}

	.order-2k-5 {
		-ms-flex-order: 5;
		order: 5;
	}

	.order-2k-6 {
		-ms-flex-order: 6;
		order: 6;
	}

	.order-2k-7 {
		-ms-flex-order: 7;
		order: 7;
	}

	.order-2k-8 {
		-ms-flex-order: 8;
		order: 8;
	}

	.order-2k-9 {
		-ms-flex-order: 9;
		order: 9;
	}

	.order-2k-10 {
		-ms-flex-order: 10;
		order: 10;
	}

	.order-2k-11 {
		-ms-flex-order: 11;
		order: 11;
	}

	.order-2k-12 {
		-ms-flex-order: 12;
		order: 12;
	}

	.offset-2k-0 {
		margin-left: 0;
	}

	.offset-2k-1 {
		margin-left: 8.333333%;
	}

	.offset-2k-2 {
		margin-left: 16.666667%;
	}

	.offset-2k-3 {
		margin-left: 25%;
	}

	.offset-2k-4 {
		margin-left: 33.333333%;
	}

	.offset-2k-5 {
		margin-left: 41.666667%;
	}

	.offset-2k-6 {
		margin-left: 50%;
	}

	.offset-2k-7 {
		margin-left: 58.333333%;
	}

	.offset-2k-8 {
		margin-left: 66.666667%;
	}

	.offset-2k-9 {
		margin-left: 75%;
	}

	.offset-2k-10 {
		margin-left: 83.333333%;
	}

	.offset-2k-11 {
		margin-left: 91.666667%;
	}
}

@media (max-width: 1899.98px) {
	.table-responsive-2k {
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		-ms-overflow-style: -ms-autohiding-scrollbar;
	}

	.table-responsive-2k > .table-bordered {
		border: 0;
	}
}


@media (max-width: 1899.98px) {
	.navbar-expand-2k > .container,
	.navbar-expand-2k > .container-fluid {
		padding-right: 0;
		padding-left: 0;
	}
}


@media (min-width: 1900px) {
	.navbar-expand-2k {
		-ms-flex-flow: row nowrap;
		flex-flow: row nowrap;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.navbar-expand-2k .navbar-nav {
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.navbar-expand-2k .navbar-nav .dropdown-menu {
		position: absolute;
	}

	.navbar-expand-2k .navbar-nav .nav-link {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}

	.navbar-expand-2k > .container,
	.navbar-expand-2k > .container-fluid {
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.navbar-expand-2k .navbar-collapse {
		display: -ms-flexbox !important;
		display: flex !important;
		-ms-flex-preferred-size: auto;
		flex-basis: auto;
	}

	.navbar-expand-2k .navbar-toggler {
		display: none;
	}
}

@media (min-width: 1900px) {
	.d-2k-none {
		display: none !important;
	}

	.d-2k-inline {
		display: inline !important;
	}

	.d-2k-inline-block {
		display: inline-block !important;
	}

	.d-2k-block {
		display: block !important;
	}

	.d-2k-table {
		display: table !important;
	}

	.d-2k-table-row {
		display: table-row !important;
	}

	.d-2k-table-cell {
		display: table-cell !important;
	}

	.d-2k-flex {
		display: -ms-flexbox !important;
		display: flex !important;
	}

	.d-2k-inline-flex {
		display: -ms-inline-flexbox !important;
		display: inline-flex !important;
	}
}

@media (min-width: 1900px) {
	.flex-2k-row {
		-ms-flex-direction: row !important;
		flex-direction: row !important;
	}

	.flex-2k-column {
		-ms-flex-direction: column !important;
		flex-direction: column !important;
	}

	.flex-2k-row-reverse {
		-ms-flex-direction: row-reverse !important;
		flex-direction: row-reverse !important;
	}

	.flex-2k-column-reverse {
		-ms-flex-direction: column-reverse !important;
		flex-direction: column-reverse !important;
	}

	.flex-2k-wrap {
		-ms-flex-wrap: wrap !important;
		flex-wrap: wrap !important;
	}

	.flex-2k-nowrap {
		-ms-flex-wrap: nowrap !important;
		flex-wrap: nowrap !important;
	}

	.flex-2k-wrap-reverse {
		-ms-flex-wrap: wrap-reverse !important;
		flex-wrap: wrap-reverse !important;
	}

	.flex-2k-fill {
		-ms-flex: 1 1 auto !important;
		flex: 1 1 auto !important;
	}

	.flex-2k-grow-0 {
		-ms-flex-positive: 0 !important;
		flex-grow: 0 !important;
	}

	.flex-2k-grow-1 {
		-ms-flex-positive: 1 !important;
		flex-grow: 1 !important;
	}

	.flex-2k-shrink-0 {
		-ms-flex-negative: 0 !important;
		flex-shrink: 0 !important;
	}

	.flex-2k-shrink-1 {
		-ms-flex-negative: 1 !important;
		flex-shrink: 1 !important;
	}

	.justify-content-2k-start {
		-ms-flex-pack: start !important;
		justify-content: flex-start !important;
	}

	.justify-content-2k-end {
		-ms-flex-pack: end !important;
		justify-content: flex-end !important;
	}

	.justify-content-2k-center {
		-ms-flex-pack: center !important;
		justify-content: center !important;
	}

	.justify-content-2k-between {
		-ms-flex-pack: justify !important;
		justify-content: space-between !important;
	}

	.justify-content-2k-around {
		-ms-flex-pack: distribute !important;
		justify-content: space-around !important;
	}

	.align-items-2k-start {
		-ms-flex-align: start !important;
		align-items: flex-start !important;
	}

	.align-items-2k-end {
		-ms-flex-align: end !important;
		align-items: flex-end !important;
	}

	.align-items-2k-center {
		-ms-flex-align: center !important;
		align-items: center !important;
	}

	.align-items-2k-baseline {
		-ms-flex-align: baseline !important;
		align-items: baseline !important;
	}

	.align-items-2k-stretch {
		-ms-flex-align: stretch !important;
		align-items: stretch !important;
	}

	.align-content-2k-start {
		-ms-flex-line-pack: start !important;
		align-content: flex-start !important;
	}

	.align-content-2k-end {
		-ms-flex-line-pack: end !important;
		align-content: flex-end !important;
	}

	.align-content-2k-center {
		-ms-flex-line-pack: center !important;
		align-content: center !important;
	}

	.align-content-2k-between {
		-ms-flex-line-pack: justify !important;
		align-content: space-between !important;
	}

	.align-content-2k-around {
		-ms-flex-line-pack: distribute !important;
		align-content: space-around !important;
	}

	.align-content-2k-stretch {
		-ms-flex-line-pack: stretch !important;
		align-content: stretch !important;
	}

	.align-self-2k-auto {
		-ms-flex-item-align: auto !important;
		align-self: auto !important;
	}

	.align-self-2k-start {
		-ms-flex-item-align: start !important;
		align-self: flex-start !important;
	}

	.align-self-2k-end {
		-ms-flex-item-align: end !important;
		align-self: flex-end !important;
	}

	.align-self-2k-center {
		-ms-flex-item-align: center !important;
		align-self: center !important;
	}

	.align-self-2k-baseline {
		-ms-flex-item-align: baseline !important;
		align-self: baseline !important;
	}

	.align-self-2k-stretch {
		-ms-flex-item-align: stretch !important;
		align-self: stretch !important;
	}
}

@media (min-width: 1900px) {
	.float-2k-left {
		float: left !important;
	}

	.float-2k-right {
		float: right !important;
	}

	.float-2k-none {
		float: none !important;
	}
}

@media (min-width: 1900px) {
	.m-2k-0 {
		margin: 0 !important;
	}

	.mt-2k-0,
	.my-2k-0 {
		margin-top: 0 !important;
	}

	.mr-2k-0,
	.mx-2k-0 {
		margin-right: 0 !important;
	}

	.mb-2k-0,
	.my-2k-0 {
		margin-bottom: 0 !important;
	}

	.ml-2k-0,
	.mx-2k-0 {
		margin-left: 0 !important;
	}

	.m-2k-1 {
		margin: 0.25rem !important;
	}

	.mt-2k-1,
	.my-2k-1 {
		margin-top: 0.25rem !important;
	}

	.mr-2k-1,
	.mx-2k-1 {
		margin-right: 0.25rem !important;
	}

	.mb-2k-1,
	.my-2k-1 {
		margin-bottom: 0.25rem !important;
	}

	.ml-2k-1,
	.mx-2k-1 {
		margin-left: 0.25rem !important;
	}

	.m-2k-2 {
		margin: 0.5rem !important;
	}

	.mt-2k-2,
	.my-2k-2 {
		margin-top: 0.5rem !important;
	}

	.mr-2k-2,
	.mx-2k-2 {
		margin-right: 0.5rem !important;
	}

	.mb-2k-2,
	.my-2k-2 {
		margin-bottom: 0.5rem !important;
	}

	.ml-2k-2,
	.mx-2k-2 {
		margin-left: 0.5rem !important;
	}

	.m-2k-3 {
		margin: 1rem !important;
	}

	.mt-2k-3,
	.my-2k-3 {
		margin-top: 1rem !important;
	}

	.mr-2k-3,
	.mx-2k-3 {
		margin-right: 1rem !important;
	}

	.mb-2k-3,
	.my-2k-3 {
		margin-bottom: 1rem !important;
	}

	.ml-2k-3,
	.mx-2k-3 {
		margin-left: 1rem !important;
	}

	.m-2k-4 {
		margin: 1.5rem !important;
	}

	.mt-2k-4,
	.my-2k-4 {
		margin-top: 1.5rem !important;
	}

	.mr-2k-4,
	.mx-2k-4 {
		margin-right: 1.5rem !important;
	}

	.mb-2k-4,
	.my-2k-4 {
		margin-bottom: 1.5rem !important;
	}

	.ml-2k-4,
	.mx-2k-4 {
		margin-left: 1.5rem !important;
	}

	.m-2k-5 {
		margin: 3rem !important;
	}

	.mt-2k-5,
	.my-2k-5 {
		margin-top: 3rem !important;
	}

	.mr-2k-5,
	.mx-2k-5 {
		margin-right: 3rem !important;
	}

	.mb-2k-5,
	.my-2k-5 {
		margin-bottom: 3rem !important;
	}

	.ml-2k-5,
	.mx-2k-5 {
		margin-left: 3rem !important;
	}

	.p-2k-0 {
		padding: 0 !important;
	}

	.pt-2k-0,
	.py-2k-0 {
		padding-top: 0 !important;
	}

	.pr-2k-0,
	.px-2k-0 {
		padding-right: 0 !important;
	}

	.pb-2k-0,
	.py-2k-0 {
		padding-bottom: 0 !important;
	}

	.pl-2k-0,
	.px-2k-0 {
		padding-left: 0 !important;
	}

	.p-2k-1 {
		padding: 0.25rem !important;
	}

	.pt-2k-1,
	.py-2k-1 {
		padding-top: 0.25rem !important;
	}

	.pr-2k-1,
	.px-2k-1 {
		padding-right: 0.25rem !important;
	}

	.pb-2k-1,
	.py-2k-1 {
		padding-bottom: 0.25rem !important;
	}

	.pl-2k-1,
	.px-2k-1 {
		padding-left: 0.25rem !important;
	}

	.p-2k-2 {
		padding: 0.5rem !important;
	}

	.pt-2k-2,
	.py-2k-2 {
		padding-top: 0.5rem !important;
	}

	.pr-2k-2,
	.px-2k-2 {
		padding-right: 0.5rem !important;
	}

	.pb-2k-2,
	.py-2k-2 {
		padding-bottom: 0.5rem !important;
	}

	.pl-2k-2,
	.px-2k-2 {
		padding-left: 0.5rem !important;
	}

	.p-2k-3 {
		padding: 1rem !important;
	}

	.pt-2k-3,
	.py-2k-3 {
		padding-top: 1rem !important;
	}

	.pr-2k-3,
	.px-2k-3 {
		padding-right: 1rem !important;
	}

	.pb-2k-3,
	.py-2k-3 {
		padding-bottom: 1rem !important;
	}

	.pl-2k-3,
	.px-2k-3 {
		padding-left: 1rem !important;
	}

	.p-2k-4 {
		padding: 1.5rem !important;
	}

	.pt-2k-4,
	.py-2k-4 {
		padding-top: 1.5rem !important;
	}

	.pr-2k-4,
	.px-2k-4 {
		padding-right: 1.5rem !important;
	}

	.pb-2k-4,
	.py-2k-4 {
		padding-bottom: 1.5rem !important;
	}

	.pl-2k-4,
	.px-2k-4 {
		padding-left: 1.5rem !important;
	}

	.p-2k-5 {
		padding: 3rem !important;
	}

	.pt-2k-5,
	.py-2k-5 {
		padding-top: 3rem !important;
	}

	.pr-2k-5,
	.px-2k-5 {
		padding-right: 3rem !important;
	}

	.pb-2k-5,
	.py-2k-5 {
		padding-bottom: 3rem !important;
	}

	.pl-2k-5,
	.px-2k-5 {
		padding-left: 3rem !important;
	}

	.m-2k-auto {
		margin: auto !important;
	}

	.mt-2k-auto,
	.my-2k-auto {
		margin-top: auto !important;
	}

	.mr-2k-auto,
	.mx-2k-auto {
		margin-right: auto !important;
	}

	.mb-2k-auto,
	.my-2k-auto {
		margin-bottom: auto !important;
	}

	.ml-2k-auto,
	.mx-2k-auto {
		margin-left: auto !important;
	}
}


@media (min-width: 1900px) {
	.text-2k-left {
		text-align: left !important;
	}

	.text-2k-right {
		text-align: right !important;
	}

	.text-2k-center {
		text-align: center !important;
	}
}

@media (min-width: 2800px) {
	.col-3k {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-ms-flex-positive: 1;
		flex-grow: 1;
		max-width: 100%;
	}

	.col-3k-auto {
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: none;
	}

	.col-3k-1 {
		-ms-flex: 0 0 8.333333%;
		flex: 0 0 8.333333%;
		max-width: 8.333333%;
	}

	.col-3k-2 {
		-ms-flex: 0 0 16.666667%;
		flex: 0 0 16.666667%;
		max-width: 16.666667%;
	}

	.col-3k-3 {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}

	.col-3k-4 {
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}

	.col-3k-5 {
		-ms-flex: 0 0 41.666667%;
		flex: 0 0 41.666667%;
		max-width: 41.666667%;
	}

	.col-3k-6 {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}

	.col-3k-7 {
		-ms-flex: 0 0 58.333333%;
		flex: 0 0 58.333333%;
		max-width: 58.333333%;
	}

	.col-3k-8 {
		-ms-flex: 0 0 66.666667%;
		flex: 0 0 66.666667%;
		max-width: 66.666667%;
	}

	.col-3k-9 {
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%;
	}

	.col-3k-10 {
		-ms-flex: 0 0 83.333333%;
		flex: 0 0 83.333333%;
		max-width: 83.333333%;
	}

	.col-3k-11 {
		-ms-flex: 0 0 91.666667%;
		flex: 0 0 91.666667%;
		max-width: 91.666667%;
	}

	.col-3k-12 {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}

	.order-3k-first {
		-ms-flex-order: -1;
		order: -1;
	}

	.order-3k-last {
		-ms-flex-order: 13;
		order: 13;
	}

	.order-3k-0 {
		-ms-flex-order: 0;
		order: 0;
	}

	.order-3k-1 {
		-ms-flex-order: 1;
		order: 1;
	}

	.order-3k-2 {
		-ms-flex-order: 2;
		order: 2;
	}

	.order-3k-3 {
		-ms-flex-order: 3;
		order: 3;
	}

	.order-3k-4 {
		-ms-flex-order: 4;
		order: 4;
	}

	.order-3k-5 {
		-ms-flex-order: 5;
		order: 5;
	}

	.order-3k-6 {
		-ms-flex-order: 6;
		order: 6;
	}

	.order-3k-7 {
		-ms-flex-order: 7;
		order: 7;
	}

	.order-3k-8 {
		-ms-flex-order: 8;
		order: 8;
	}

	.order-3k-9 {
		-ms-flex-order: 9;
		order: 9;
	}

	.order-3k-10 {
		-ms-flex-order: 10;
		order: 10;
	}

	.order-3k-11 {
		-ms-flex-order: 11;
		order: 11;
	}

	.order-3k-12 {
		-ms-flex-order: 12;
		order: 12;
	}

	.offset-3k-0 {
		margin-left: 0;
	}

	.offset-3k-1 {
		margin-left: 8.333333%;
	}

	.offset-3k-2 {
		margin-left: 16.666667%;
	}

	.offset-3k-3 {
		margin-left: 25%;
	}

	.offset-3k-4 {
		margin-left: 33.333333%;
	}

	.offset-3k-5 {
		margin-left: 41.666667%;
	}

	.offset-3k-6 {
		margin-left: 50%;
	}

	.offset-3k-7 {
		margin-left: 58.333333%;
	}

	.offset-3k-8 {
		margin-left: 66.666667%;
	}

	.offset-3k-9 {
		margin-left: 75%;
	}

	.offset-3k-10 {
		margin-left: 83.333333%;
	}

	.offset-3k-11 {
		margin-left: 91.666667%;
	}
}

@media (max-width: 2799.98px) {
	.table-responsive-3k {
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		-ms-overflow-style: -ms-autohiding-scrollbar;
	}

	.table-responsive-3k > .table-bordered {
		border: 0;
	}
}


@media (max-width: 2799.98px) {
	.navbar-expand-3k > .container,
	.navbar-expand-3k > .container-fluid {
		padding-right: 0;
		padding-left: 0;
	}
}


@media (min-width: 2800px) {
	.navbar-expand-3k {
		-ms-flex-flow: row nowrap;
		flex-flow: row nowrap;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.navbar-expand-3k .navbar-nav {
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.navbar-expand-3k .navbar-nav .dropdown-menu {
		position: absolute;
	}

	.navbar-expand-3k .navbar-nav .nav-link {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}

	.navbar-expand-3k > .container,
	.navbar-expand-3k > .container-fluid {
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.navbar-expand-3k .navbar-collapse {
		display: -ms-flexbox !important;
		display: flex !important;
		-ms-flex-preferred-size: auto;
		flex-basis: auto;
	}

	.navbar-expand-3k .navbar-toggler {
		display: none;
	}
}

@media (min-width: 2800px) {
	.d-3k-none {
		display: none !important;
	}

	.d-3k-inline {
		display: inline !important;
	}

	.d-3k-inline-block {
		display: inline-block !important;
	}

	.d-3k-block {
		display: block !important;
	}

	.d-3k-table {
		display: table !important;
	}

	.d-3k-table-row {
		display: table-row !important;
	}

	.d-3k-table-cell {
		display: table-cell !important;
	}

	.d-3k-flex {
		display: -ms-flexbox !important;
		display: flex !important;
	}

	.d-3k-inline-flex {
		display: -ms-inline-flexbox !important;
		display: inline-flex !important;
	}
}

@media (min-width: 2800px) {
	.flex-3k-row {
		-ms-flex-direction: row !important;
		flex-direction: row !important;
	}

	.flex-3k-column {
		-ms-flex-direction: column !important;
		flex-direction: column !important;
	}

	.flex-3k-row-reverse {
		-ms-flex-direction: row-reverse !important;
		flex-direction: row-reverse !important;
	}

	.flex-3k-column-reverse {
		-ms-flex-direction: column-reverse !important;
		flex-direction: column-reverse !important;
	}

	.flex-3k-wrap {
		-ms-flex-wrap: wrap !important;
		flex-wrap: wrap !important;
	}

	.flex-3k-nowrap {
		-ms-flex-wrap: nowrap !important;
		flex-wrap: nowrap !important;
	}

	.flex-3k-wrap-reverse {
		-ms-flex-wrap: wrap-reverse !important;
		flex-wrap: wrap-reverse !important;
	}

	.flex-3k-fill {
		-ms-flex: 1 1 auto !important;
		flex: 1 1 auto !important;
	}

	.flex-3k-grow-0 {
		-ms-flex-positive: 0 !important;
		flex-grow: 0 !important;
	}

	.flex-3k-grow-1 {
		-ms-flex-positive: 1 !important;
		flex-grow: 1 !important;
	}

	.flex-3k-shrink-0 {
		-ms-flex-negative: 0 !important;
		flex-shrink: 0 !important;
	}

	.flex-3k-shrink-1 {
		-ms-flex-negative: 1 !important;
		flex-shrink: 1 !important;
	}

	.justify-content-3k-start {
		-ms-flex-pack: start !important;
		justify-content: flex-start !important;
	}

	.justify-content-3k-end {
		-ms-flex-pack: end !important;
		justify-content: flex-end !important;
	}

	.justify-content-3k-center {
		-ms-flex-pack: center !important;
		justify-content: center !important;
	}

	.justify-content-3k-between {
		-ms-flex-pack: justify !important;
		justify-content: space-between !important;
	}

	.justify-content-3k-around {
		-ms-flex-pack: distribute !important;
		justify-content: space-around !important;
	}

	.align-items-3k-start {
		-ms-flex-align: start !important;
		align-items: flex-start !important;
	}

	.align-items-3k-end {
		-ms-flex-align: end !important;
		align-items: flex-end !important;
	}

	.align-items-3k-center {
		-ms-flex-align: center !important;
		align-items: center !important;
	}

	.align-items-3k-baseline {
		-ms-flex-align: baseline !important;
		align-items: baseline !important;
	}

	.align-items-3k-stretch {
		-ms-flex-align: stretch !important;
		align-items: stretch !important;
	}

	.align-content-3k-start {
		-ms-flex-line-pack: start !important;
		align-content: flex-start !important;
	}

	.align-content-3k-end {
		-ms-flex-line-pack: end !important;
		align-content: flex-end !important;
	}

	.align-content-3k-center {
		-ms-flex-line-pack: center !important;
		align-content: center !important;
	}

	.align-content-3k-between {
		-ms-flex-line-pack: justify !important;
		align-content: space-between !important;
	}

	.align-content-3k-around {
		-ms-flex-line-pack: distribute !important;
		align-content: space-around !important;
	}

	.align-content-3k-stretch {
		-ms-flex-line-pack: stretch !important;
		align-content: stretch !important;
	}

	.align-self-3k-auto {
		-ms-flex-item-align: auto !important;
		align-self: auto !important;
	}

	.align-self-3k-start {
		-ms-flex-item-align: start !important;
		align-self: flex-start !important;
	}

	.align-self-3k-end {
		-ms-flex-item-align: end !important;
		align-self: flex-end !important;
	}

	.align-self-3k-center {
		-ms-flex-item-align: center !important;
		align-self: center !important;
	}

	.align-self-3k-baseline {
		-ms-flex-item-align: baseline !important;
		align-self: baseline !important;
	}

	.align-self-3k-stretch {
		-ms-flex-item-align: stretch !important;
		align-self: stretch !important;
	}
}

@media (min-width: 2800px) {
	.float-3k-left {
		float: left !important;
	}

	.float-3k-right {
		float: right !important;
	}

	.float-3k-none {
		float: none !important;
	}
}

@media (min-width: 2800px) {
	.m-3k-0 {
		margin: 0 !important;
	}

	.mt-3k-0,
	.my-3k-0 {
		margin-top: 0 !important;
	}

	.mr-3k-0,
	.mx-3k-0 {
		margin-right: 0 !important;
	}

	.mb-3k-0,
	.my-3k-0 {
		margin-bottom: 0 !important;
	}

	.ml-3k-0,
	.mx-3k-0 {
		margin-left: 0 !important;
	}

	.m-3k-1 {
		margin: 0.25rem !important;
	}

	.mt-3k-1,
	.my-3k-1 {
		margin-top: 0.25rem !important;
	}

	.mr-3k-1,
	.mx-3k-1 {
		margin-right: 0.25rem !important;
	}

	.mb-3k-1,
	.my-3k-1 {
		margin-bottom: 0.25rem !important;
	}

	.ml-3k-1,
	.mx-3k-1 {
		margin-left: 0.25rem !important;
	}

	.m-3k-2 {
		margin: 0.5rem !important;
	}

	.mt-3k-2,
	.my-3k-2 {
		margin-top: 0.5rem !important;
	}

	.mr-3k-2,
	.mx-3k-2 {
		margin-right: 0.5rem !important;
	}

	.mb-3k-2,
	.my-3k-2 {
		margin-bottom: 0.5rem !important;
	}

	.ml-3k-2,
	.mx-3k-2 {
		margin-left: 0.5rem !important;
	}

	.m-3k-3 {
		margin: 1rem !important;
	}

	.mt-3k-3,
	.my-3k-3 {
		margin-top: 1rem !important;
	}

	.mr-3k-3,
	.mx-3k-3 {
		margin-right: 1rem !important;
	}

	.mb-3k-3,
	.my-3k-3 {
		margin-bottom: 1rem !important;
	}

	.ml-3k-3,
	.mx-3k-3 {
		margin-left: 1rem !important;
	}

	.m-3k-4 {
		margin: 1.5rem !important;
	}

	.mt-3k-4,
	.my-3k-4 {
		margin-top: 1.5rem !important;
	}

	.mr-3k-4,
	.mx-3k-4 {
		margin-right: 1.5rem !important;
	}

	.mb-3k-4,
	.my-3k-4 {
		margin-bottom: 1.5rem !important;
	}

	.ml-3k-4,
	.mx-3k-4 {
		margin-left: 1.5rem !important;
	}

	.m-3k-5 {
		margin: 3rem !important;
	}

	.mt-3k-5,
	.my-3k-5 {
		margin-top: 3rem !important;
	}

	.mr-3k-5,
	.mx-3k-5 {
		margin-right: 3rem !important;
	}

	.mb-3k-5,
	.my-3k-5 {
		margin-bottom: 3rem !important;
	}

	.ml-3k-5,
	.mx-3k-5 {
		margin-left: 3rem !important;
	}

	.p-3k-0 {
		padding: 0 !important;
	}

	.pt-3k-0,
	.py-3k-0 {
		padding-top: 0 !important;
	}

	.pr-3k-0,
	.px-3k-0 {
		padding-right: 0 !important;
	}

	.pb-3k-0,
	.py-3k-0 {
		padding-bottom: 0 !important;
	}

	.pl-3k-0,
	.px-3k-0 {
		padding-left: 0 !important;
	}

	.p-3k-1 {
		padding: 0.25rem !important;
	}

	.pt-3k-1,
	.py-3k-1 {
		padding-top: 0.25rem !important;
	}

	.pr-3k-1,
	.px-3k-1 {
		padding-right: 0.25rem !important;
	}

	.pb-3k-1,
	.py-3k-1 {
		padding-bottom: 0.25rem !important;
	}

	.pl-3k-1,
	.px-3k-1 {
		padding-left: 0.25rem !important;
	}

	.p-3k-2 {
		padding: 0.5rem !important;
	}

	.pt-3k-2,
	.py-3k-2 {
		padding-top: 0.5rem !important;
	}

	.pr-3k-2,
	.px-3k-2 {
		padding-right: 0.5rem !important;
	}

	.pb-3k-2,
	.py-3k-2 {
		padding-bottom: 0.5rem !important;
	}

	.pl-3k-2,
	.px-3k-2 {
		padding-left: 0.5rem !important;
	}

	.p-3k-3 {
		padding: 1rem !important;
	}

	.pt-3k-3,
	.py-3k-3 {
		padding-top: 1rem !important;
	}

	.pr-3k-3,
	.px-3k-3 {
		padding-right: 1rem !important;
	}

	.pb-3k-3,
	.py-3k-3 {
		padding-bottom: 1rem !important;
	}

	.pl-3k-3,
	.px-3k-3 {
		padding-left: 1rem !important;
	}

	.p-3k-4 {
		padding: 1.5rem !important;
	}

	.pt-3k-4,
	.py-3k-4 {
		padding-top: 1.5rem !important;
	}

	.pr-3k-4,
	.px-3k-4 {
		padding-right: 1.5rem !important;
	}

	.pb-3k-4,
	.py-3k-4 {
		padding-bottom: 1.5rem !important;
	}

	.pl-3k-4,
	.px-3k-4 {
		padding-left: 1.5rem !important;
	}

	.p-3k-5 {
		padding: 3rem !important;
	}

	.pt-3k-5,
	.py-3k-5 {
		padding-top: 3rem !important;
	}

	.pr-3k-5,
	.px-3k-5 {
		padding-right: 3rem !important;
	}

	.pb-3k-5,
	.py-3k-5 {
		padding-bottom: 3rem !important;
	}

	.pl-3k-5,
	.px-3k-5 {
		padding-left: 3rem !important;
	}

	.m-3k-auto {
		margin: auto !important;
	}

	.mt-3k-auto,
	.my-3k-auto {
		margin-top: auto !important;
	}

	.mr-3k-auto,
	.mx-3k-auto {
		margin-right: auto !important;
	}

	.mb-3k-auto,
	.my-3k-auto {
		margin-bottom: auto !important;
	}

	.ml-3k-auto,
	.mx-3k-auto {
		margin-left: auto !important;
	}
}


@media (min-width: 2800px) {
	.text-3k-left {
		text-align: left !important;
	}

	.text-3k-right {
		text-align: right !important;
	}

	.text-3k-center {
		text-align: center !important;
	}
}

@media (min-width: 3800px) {
	.col-4k {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-ms-flex-positive: 1;
		flex-grow: 1;
		max-width: 100%;
	}

	.col-4k-auto {
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: none;
	}

	.col-4k-1 {
		-ms-flex: 0 0 8.333333%;
		flex: 0 0 8.333333%;
		max-width: 8.333333%;
	}

	.col-4k-2 {
		-ms-flex: 0 0 16.666667%;
		flex: 0 0 16.666667%;
		max-width: 16.666667%;
	}

	.col-4k-3 {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}

	.col-4k-4 {
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}

	.col-4k-5 {
		-ms-flex: 0 0 41.666667%;
		flex: 0 0 41.666667%;
		max-width: 41.666667%;
	}

	.col-4k-6 {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}

	.col-4k-7 {
		-ms-flex: 0 0 58.333333%;
		flex: 0 0 58.333333%;
		max-width: 58.333333%;
	}

	.col-4k-8 {
		-ms-flex: 0 0 66.666667%;
		flex: 0 0 66.666667%;
		max-width: 66.666667%;
	}

	.col-4k-9 {
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%;
	}

	.col-4k-10 {
		-ms-flex: 0 0 83.333333%;
		flex: 0 0 83.333333%;
		max-width: 83.333333%;
	}

	.col-4k-11 {
		-ms-flex: 0 0 91.666667%;
		flex: 0 0 91.666667%;
		max-width: 91.666667%;
	}

	.col-4k-12 {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}

	.order-4k-first {
		-ms-flex-order: -1;
		order: -1;
	}

	.order-4k-last {
		-ms-flex-order: 13;
		order: 13;
	}

	.order-4k-0 {
		-ms-flex-order: 0;
		order: 0;
	}

	.order-4k-1 {
		-ms-flex-order: 1;
		order: 1;
	}

	.order-4k-2 {
		-ms-flex-order: 2;
		order: 2;
	}

	.order-4k-3 {
		-ms-flex-order: 3;
		order: 3;
	}

	.order-4k-4 {
		-ms-flex-order: 4;
		order: 4;
	}

	.order-4k-5 {
		-ms-flex-order: 5;
		order: 5;
	}

	.order-4k-6 {
		-ms-flex-order: 6;
		order: 6;
	}

	.order-4k-7 {
		-ms-flex-order: 7;
		order: 7;
	}

	.order-4k-8 {
		-ms-flex-order: 8;
		order: 8;
	}

	.order-4k-9 {
		-ms-flex-order: 9;
		order: 9;
	}

	.order-4k-10 {
		-ms-flex-order: 10;
		order: 10;
	}

	.order-4k-11 {
		-ms-flex-order: 11;
		order: 11;
	}

	.order-4k-12 {
		-ms-flex-order: 12;
		order: 12;
	}

	.offset-4k-0 {
		margin-left: 0;
	}

	.offset-4k-1 {
		margin-left: 8.333333%;
	}

	.offset-4k-2 {
		margin-left: 16.666667%;
	}

	.offset-4k-3 {
		margin-left: 25%;
	}

	.offset-4k-4 {
		margin-left: 33.333333%;
	}

	.offset-4k-5 {
		margin-left: 41.666667%;
	}

	.offset-4k-6 {
		margin-left: 50%;
	}

	.offset-4k-7 {
		margin-left: 58.333333%;
	}

	.offset-4k-8 {
		margin-left: 66.666667%;
	}

	.offset-4k-9 {
		margin-left: 75%;
	}

	.offset-4k-10 {
		margin-left: 83.333333%;
	}

	.offset-4k-11 {
		margin-left: 91.666667%;
	}
}

@media (max-width: 3799.98px) {
	.table-responsive-4k {
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		-ms-overflow-style: -ms-autohiding-scrollbar;
	}

	.table-responsive-4k > .table-bordered {
		border: 0;
	}
}


@media (max-width: 3799.98px) {
	.navbar-expand-4k > .container,
	.navbar-expand-4k > .container-fluid {
		padding-right: 0;
		padding-left: 0;
	}
}


@media (min-width: 3800px) {
	.navbar-expand-4k {
		-ms-flex-flow: row nowrap;
		flex-flow: row nowrap;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.navbar-expand-4k .navbar-nav {
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.navbar-expand-4k .navbar-nav .dropdown-menu {
		position: absolute;
	}

	.navbar-expand-4k .navbar-nav .nav-link {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}

	.navbar-expand-4k > .container,
	.navbar-expand-4k > .container-fluid {
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.navbar-expand-4k .navbar-collapse {
		display: -ms-flexbox !important;
		display: flex !important;
		-ms-flex-preferred-size: auto;
		flex-basis: auto;
	}

	.navbar-expand-4k .navbar-toggler {
		display: none;
	}
}

@media (min-width: 3800px) {
	.d-4k-none {
		display: none !important;
	}

	.d-4k-inline {
		display: inline !important;
	}

	.d-4k-inline-block {
		display: inline-block !important;
	}

	.d-4k-block {
		display: block !important;
	}

	.d-4k-table {
		display: table !important;
	}

	.d-4k-table-row {
		display: table-row !important;
	}

	.d-4k-table-cell {
		display: table-cell !important;
	}

	.d-4k-flex {
		display: -ms-flexbox !important;
		display: flex !important;
	}

	.d-4k-inline-flex {
		display: -ms-inline-flexbox !important;
		display: inline-flex !important;
	}
}

@media (min-width: 3800px) {
	.flex-4k-row {
		-ms-flex-direction: row !important;
		flex-direction: row !important;
	}

	.flex-4k-column {
		-ms-flex-direction: column !important;
		flex-direction: column !important;
	}

	.flex-4k-row-reverse {
		-ms-flex-direction: row-reverse !important;
		flex-direction: row-reverse !important;
	}

	.flex-4k-column-reverse {
		-ms-flex-direction: column-reverse !important;
		flex-direction: column-reverse !important;
	}

	.flex-4k-wrap {
		-ms-flex-wrap: wrap !important;
		flex-wrap: wrap !important;
	}

	.flex-4k-nowrap {
		-ms-flex-wrap: nowrap !important;
		flex-wrap: nowrap !important;
	}

	.flex-4k-wrap-reverse {
		-ms-flex-wrap: wrap-reverse !important;
		flex-wrap: wrap-reverse !important;
	}

	.flex-4k-fill {
		-ms-flex: 1 1 auto !important;
		flex: 1 1 auto !important;
	}

	.flex-4k-grow-0 {
		-ms-flex-positive: 0 !important;
		flex-grow: 0 !important;
	}

	.flex-4k-grow-1 {
		-ms-flex-positive: 1 !important;
		flex-grow: 1 !important;
	}

	.flex-4k-shrink-0 {
		-ms-flex-negative: 0 !important;
		flex-shrink: 0 !important;
	}

	.flex-4k-shrink-1 {
		-ms-flex-negative: 1 !important;
		flex-shrink: 1 !important;
	}

	.justify-content-4k-start {
		-ms-flex-pack: start !important;
		justify-content: flex-start !important;
	}

	.justify-content-4k-end {
		-ms-flex-pack: end !important;
		justify-content: flex-end !important;
	}

	.justify-content-4k-center {
		-ms-flex-pack: center !important;
		justify-content: center !important;
	}

	.justify-content-4k-between {
		-ms-flex-pack: justify !important;
		justify-content: space-between !important;
	}

	.justify-content-4k-around {
		-ms-flex-pack: distribute !important;
		justify-content: space-around !important;
	}

	.align-items-4k-start {
		-ms-flex-align: start !important;
		align-items: flex-start !important;
	}

	.align-items-4k-end {
		-ms-flex-align: end !important;
		align-items: flex-end !important;
	}

	.align-items-4k-center {
		-ms-flex-align: center !important;
		align-items: center !important;
	}

	.align-items-4k-baseline {
		-ms-flex-align: baseline !important;
		align-items: baseline !important;
	}

	.align-items-4k-stretch {
		-ms-flex-align: stretch !important;
		align-items: stretch !important;
	}

	.align-content-4k-start {
		-ms-flex-line-pack: start !important;
		align-content: flex-start !important;
	}

	.align-content-4k-end {
		-ms-flex-line-pack: end !important;
		align-content: flex-end !important;
	}

	.align-content-4k-center {
		-ms-flex-line-pack: center !important;
		align-content: center !important;
	}

	.align-content-4k-between {
		-ms-flex-line-pack: justify !important;
		align-content: space-between !important;
	}

	.align-content-4k-around {
		-ms-flex-line-pack: distribute !important;
		align-content: space-around !important;
	}

	.align-content-4k-stretch {
		-ms-flex-line-pack: stretch !important;
		align-content: stretch !important;
	}

	.align-self-4k-auto {
		-ms-flex-item-align: auto !important;
		align-self: auto !important;
	}

	.align-self-4k-start {
		-ms-flex-item-align: start !important;
		align-self: flex-start !important;
	}

	.align-self-4k-end {
		-ms-flex-item-align: end !important;
		align-self: flex-end !important;
	}

	.align-self-4k-center {
		-ms-flex-item-align: center !important;
		align-self: center !important;
	}

	.align-self-4k-baseline {
		-ms-flex-item-align: baseline !important;
		align-self: baseline !important;
	}

	.align-self-4k-stretch {
		-ms-flex-item-align: stretch !important;
		align-self: stretch !important;
	}
}

@media (min-width: 3800px) {
	.float-4k-left {
		float: left !important;
	}

	.float-4k-right {
		float: right !important;
	}

	.float-4k-none {
		float: none !important;
	}
}

@media (min-width: 3800px) {
	.m-4k-0 {
		margin: 0 !important;
	}

	.mt-4k-0,
	.my-4k-0 {
		margin-top: 0 !important;
	}

	.mr-4k-0,
	.mx-4k-0 {
		margin-right: 0 !important;
	}

	.mb-4k-0,
	.my-4k-0 {
		margin-bottom: 0 !important;
	}

	.ml-4k-0,
	.mx-4k-0 {
		margin-left: 0 !important;
	}

	.m-4k-1 {
		margin: 0.25rem !important;
	}

	.mt-4k-1,
	.my-4k-1 {
		margin-top: 0.25rem !important;
	}

	.mr-4k-1,
	.mx-4k-1 {
		margin-right: 0.25rem !important;
	}

	.mb-4k-1,
	.my-4k-1 {
		margin-bottom: 0.25rem !important;
	}

	.ml-4k-1,
	.mx-4k-1 {
		margin-left: 0.25rem !important;
	}

	.m-4k-2 {
		margin: 0.5rem !important;
	}

	.mt-4k-2,
	.my-4k-2 {
		margin-top: 0.5rem !important;
	}

	.mr-4k-2,
	.mx-4k-2 {
		margin-right: 0.5rem !important;
	}

	.mb-4k-2,
	.my-4k-2 {
		margin-bottom: 0.5rem !important;
	}

	.ml-4k-2,
	.mx-4k-2 {
		margin-left: 0.5rem !important;
	}

	.m-4k-3 {
		margin: 1rem !important;
	}

	.mt-4k-3,
	.my-4k-3 {
		margin-top: 1rem !important;
	}

	.mr-4k-3,
	.mx-4k-3 {
		margin-right: 1rem !important;
	}

	.mb-4k-3,
	.my-4k-3 {
		margin-bottom: 1rem !important;
	}

	.ml-4k-3,
	.mx-4k-3 {
		margin-left: 1rem !important;
	}

	.m-4k-4 {
		margin: 1.5rem !important;
	}

	.mt-4k-4,
	.my-4k-4 {
		margin-top: 1.5rem !important;
	}

	.mr-4k-4,
	.mx-4k-4 {
		margin-right: 1.5rem !important;
	}

	.mb-4k-4,
	.my-4k-4 {
		margin-bottom: 1.5rem !important;
	}

	.ml-4k-4,
	.mx-4k-4 {
		margin-left: 1.5rem !important;
	}

	.m-4k-5 {
		margin: 3rem !important;
	}

	.mt-4k-5,
	.my-4k-5 {
		margin-top: 3rem !important;
	}

	.mr-4k-5,
	.mx-4k-5 {
		margin-right: 3rem !important;
	}

	.mb-4k-5,
	.my-4k-5 {
		margin-bottom: 3rem !important;
	}

	.ml-4k-5,
	.mx-4k-5 {
		margin-left: 3rem !important;
	}

	.p-4k-0 {
		padding: 0 !important;
	}

	.pt-4k-0,
	.py-4k-0 {
		padding-top: 0 !important;
	}

	.pr-4k-0,
	.px-4k-0 {
		padding-right: 0 !important;
	}

	.pb-4k-0,
	.py-4k-0 {
		padding-bottom: 0 !important;
	}

	.pl-4k-0,
	.px-4k-0 {
		padding-left: 0 !important;
	}

	.p-4k-1 {
		padding: 0.25rem !important;
	}

	.pt-4k-1,
	.py-4k-1 {
		padding-top: 0.25rem !important;
	}

	.pr-4k-1,
	.px-4k-1 {
		padding-right: 0.25rem !important;
	}

	.pb-4k-1,
	.py-4k-1 {
		padding-bottom: 0.25rem !important;
	}

	.pl-4k-1,
	.px-4k-1 {
		padding-left: 0.25rem !important;
	}

	.p-4k-2 {
		padding: 0.5rem !important;
	}

	.pt-4k-2,
	.py-4k-2 {
		padding-top: 0.5rem !important;
	}

	.pr-4k-2,
	.px-4k-2 {
		padding-right: 0.5rem !important;
	}

	.pb-4k-2,
	.py-4k-2 {
		padding-bottom: 0.5rem !important;
	}

	.pl-4k-2,
	.px-4k-2 {
		padding-left: 0.5rem !important;
	}

	.p-4k-3 {
		padding: 1rem !important;
	}

	.pt-4k-3,
	.py-4k-3 {
		padding-top: 1rem !important;
	}

	.pr-4k-3,
	.px-4k-3 {
		padding-right: 1rem !important;
	}

	.pb-4k-3,
	.py-4k-3 {
		padding-bottom: 1rem !important;
	}

	.pl-4k-3,
	.px-4k-3 {
		padding-left: 1rem !important;
	}

	.p-4k-4 {
		padding: 1.5rem !important;
	}

	.pt-4k-4,
	.py-4k-4 {
		padding-top: 1.5rem !important;
	}

	.pr-4k-4,
	.px-4k-4 {
		padding-right: 1.5rem !important;
	}

	.pb-4k-4,
	.py-4k-4 {
		padding-bottom: 1.5rem !important;
	}

	.pl-4k-4,
	.px-4k-4 {
		padding-left: 1.5rem !important;
	}

	.p-4k-5 {
		padding: 3rem !important;
	}

	.pt-4k-5,
	.py-4k-5 {
		padding-top: 3rem !important;
	}

	.pr-4k-5,
	.px-4k-5 {
		padding-right: 3rem !important;
	}

	.pb-4k-5,
	.py-4k-5 {
		padding-bottom: 3rem !important;
	}

	.pl-4k-5,
	.px-4k-5 {
		padding-left: 3rem !important;
	}

	.m-4k-auto {
		margin: auto !important;
	}

	.mt-4k-auto,
	.my-4k-auto {
		margin-top: auto !important;
	}

	.mr-4k-auto,
	.mx-4k-auto {
		margin-right: auto !important;
	}

	.mb-4k-auto,
	.my-4k-auto {
		margin-bottom: auto !important;
	}

	.ml-4k-auto,
	.mx-4k-auto {
		margin-left: auto !important;
	}
}


@media (min-width: 3800px) {
	.text-4k-left {
		text-align: left !important;
	}

	.text-4k-right {
		text-align: right !important;
	}

	.text-4k-center {
		text-align: center !important;
	}
}

@media (min-width: 5700px) {
	.col-6k {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-ms-flex-positive: 1;
		flex-grow: 1;
		max-width: 100%;
	}

	.col-6k-auto {
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: none;
	}

	.col-6k-1 {
		-ms-flex: 0 0 8.333333%;
		flex: 0 0 8.333333%;
		max-width: 8.333333%;
	}

	.col-6k-2 {
		-ms-flex: 0 0 16.666667%;
		flex: 0 0 16.666667%;
		max-width: 16.666667%;
	}

	.col-6k-3 {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}

	.col-6k-4 {
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}

	.col-6k-5 {
		-ms-flex: 0 0 41.666667%;
		flex: 0 0 41.666667%;
		max-width: 41.666667%;
	}

	.col-6k-6 {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}

	.col-6k-7 {
		-ms-flex: 0 0 58.333333%;
		flex: 0 0 58.333333%;
		max-width: 58.333333%;
	}

	.col-6k-8 {
		-ms-flex: 0 0 66.666667%;
		flex: 0 0 66.666667%;
		max-width: 66.666667%;
	}

	.col-6k-9 {
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%;
	}

	.col-6k-10 {
		-ms-flex: 0 0 83.333333%;
		flex: 0 0 83.333333%;
		max-width: 83.333333%;
	}

	.col-6k-11 {
		-ms-flex: 0 0 91.666667%;
		flex: 0 0 91.666667%;
		max-width: 91.666667%;
	}

	.col-6k-12 {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}

	.order-6k-first {
		-ms-flex-order: -1;
		order: -1;
	}

	.order-6k-last {
		-ms-flex-order: 13;
		order: 13;
	}

	.order-6k-0 {
		-ms-flex-order: 0;
		order: 0;
	}

	.order-6k-1 {
		-ms-flex-order: 1;
		order: 1;
	}

	.order-6k-2 {
		-ms-flex-order: 2;
		order: 2;
	}

	.order-6k-3 {
		-ms-flex-order: 3;
		order: 3;
	}

	.order-6k-4 {
		-ms-flex-order: 4;
		order: 4;
	}

	.order-6k-5 {
		-ms-flex-order: 5;
		order: 5;
	}

	.order-6k-6 {
		-ms-flex-order: 6;
		order: 6;
	}

	.order-6k-7 {
		-ms-flex-order: 7;
		order: 7;
	}

	.order-6k-8 {
		-ms-flex-order: 8;
		order: 8;
	}

	.order-6k-9 {
		-ms-flex-order: 9;
		order: 9;
	}

	.order-6k-10 {
		-ms-flex-order: 10;
		order: 10;
	}

	.order-6k-11 {
		-ms-flex-order: 11;
		order: 11;
	}

	.order-6k-12 {
		-ms-flex-order: 12;
		order: 12;
	}

	.offset-6k-0 {
		margin-left: 0;
	}

	.offset-6k-1 {
		margin-left: 8.333333%;
	}

	.offset-6k-2 {
		margin-left: 16.666667%;
	}

	.offset-6k-3 {
		margin-left: 25%;
	}

	.offset-6k-4 {
		margin-left: 33.333333%;
	}

	.offset-6k-5 {
		margin-left: 41.666667%;
	}

	.offset-6k-6 {
		margin-left: 50%;
	}

	.offset-6k-7 {
		margin-left: 58.333333%;
	}

	.offset-6k-8 {
		margin-left: 66.666667%;
	}

	.offset-6k-9 {
		margin-left: 75%;
	}

	.offset-6k-10 {
		margin-left: 83.333333%;
	}

	.offset-6k-11 {
		margin-left: 91.666667%;
	}
}

@media (max-width: 5699.98px) {
	.table-responsive-6k {
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		-ms-overflow-style: -ms-autohiding-scrollbar;
	}

	.table-responsive-6k > .table-bordered {
		border: 0;
	}
}


@media (max-width: 5699.98px) {
	.navbar-expand-6k > .container,
	.navbar-expand-6k > .container-fluid {
		padding-right: 0;
		padding-left: 0;
	}
}


@media (min-width: 5700px) {
	.navbar-expand-6k {
		-ms-flex-flow: row nowrap;
		flex-flow: row nowrap;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.navbar-expand-6k .navbar-nav {
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.navbar-expand-6k .navbar-nav .dropdown-menu {
		position: absolute;
	}

	.navbar-expand-6k .navbar-nav .nav-link {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}

	.navbar-expand-6k > .container,
	.navbar-expand-6k > .container-fluid {
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.navbar-expand-6k .navbar-collapse {
		display: -ms-flexbox !important;
		display: flex !important;
		-ms-flex-preferred-size: auto;
		flex-basis: auto;
	}

	.navbar-expand-6k .navbar-toggler {
		display: none;
	}
}

@media (min-width: 5700px) {
	.d-6k-none {
		display: none !important;
	}

	.d-6k-inline {
		display: inline !important;
	}

	.d-6k-inline-block {
		display: inline-block !important;
	}

	.d-6k-block {
		display: block !important;
	}

	.d-6k-table {
		display: table !important;
	}

	.d-6k-table-row {
		display: table-row !important;
	}

	.d-6k-table-cell {
		display: table-cell !important;
	}

	.d-6k-flex {
		display: -ms-flexbox !important;
		display: flex !important;
	}

	.d-6k-inline-flex {
		display: -ms-inline-flexbox !important;
		display: inline-flex !important;
	}
}

@media (min-width: 5700px) {
	.flex-6k-row {
		-ms-flex-direction: row !important;
		flex-direction: row !important;
	}

	.flex-6k-column {
		-ms-flex-direction: column !important;
		flex-direction: column !important;
	}

	.flex-6k-row-reverse {
		-ms-flex-direction: row-reverse !important;
		flex-direction: row-reverse !important;
	}

	.flex-6k-column-reverse {
		-ms-flex-direction: column-reverse !important;
		flex-direction: column-reverse !important;
	}

	.flex-6k-wrap {
		-ms-flex-wrap: wrap !important;
		flex-wrap: wrap !important;
	}

	.flex-6k-nowrap {
		-ms-flex-wrap: nowrap !important;
		flex-wrap: nowrap !important;
	}

	.flex-6k-wrap-reverse {
		-ms-flex-wrap: wrap-reverse !important;
		flex-wrap: wrap-reverse !important;
	}

	.flex-6k-fill {
		-ms-flex: 1 1 auto !important;
		flex: 1 1 auto !important;
	}

	.flex-6k-grow-0 {
		-ms-flex-positive: 0 !important;
		flex-grow: 0 !important;
	}

	.flex-6k-grow-1 {
		-ms-flex-positive: 1 !important;
		flex-grow: 1 !important;
	}

	.flex-6k-shrink-0 {
		-ms-flex-negative: 0 !important;
		flex-shrink: 0 !important;
	}

	.flex-6k-shrink-1 {
		-ms-flex-negative: 1 !important;
		flex-shrink: 1 !important;
	}

	.justify-content-6k-start {
		-ms-flex-pack: start !important;
		justify-content: flex-start !important;
	}

	.justify-content-6k-end {
		-ms-flex-pack: end !important;
		justify-content: flex-end !important;
	}

	.justify-content-6k-center {
		-ms-flex-pack: center !important;
		justify-content: center !important;
	}

	.justify-content-6k-between {
		-ms-flex-pack: justify !important;
		justify-content: space-between !important;
	}

	.justify-content-6k-around {
		-ms-flex-pack: distribute !important;
		justify-content: space-around !important;
	}

	.align-items-6k-start {
		-ms-flex-align: start !important;
		align-items: flex-start !important;
	}

	.align-items-6k-end {
		-ms-flex-align: end !important;
		align-items: flex-end !important;
	}

	.align-items-6k-center {
		-ms-flex-align: center !important;
		align-items: center !important;
	}

	.align-items-6k-baseline {
		-ms-flex-align: baseline !important;
		align-items: baseline !important;
	}

	.align-items-6k-stretch {
		-ms-flex-align: stretch !important;
		align-items: stretch !important;
	}

	.align-content-6k-start {
		-ms-flex-line-pack: start !important;
		align-content: flex-start !important;
	}

	.align-content-6k-end {
		-ms-flex-line-pack: end !important;
		align-content: flex-end !important;
	}

	.align-content-6k-center {
		-ms-flex-line-pack: center !important;
		align-content: center !important;
	}

	.align-content-6k-between {
		-ms-flex-line-pack: justify !important;
		align-content: space-between !important;
	}

	.align-content-6k-around {
		-ms-flex-line-pack: distribute !important;
		align-content: space-around !important;
	}

	.align-content-6k-stretch {
		-ms-flex-line-pack: stretch !important;
		align-content: stretch !important;
	}

	.align-self-6k-auto {
		-ms-flex-item-align: auto !important;
		align-self: auto !important;
	}

	.align-self-6k-start {
		-ms-flex-item-align: start !important;
		align-self: flex-start !important;
	}

	.align-self-6k-end {
		-ms-flex-item-align: end !important;
		align-self: flex-end !important;
	}

	.align-self-6k-center {
		-ms-flex-item-align: center !important;
		align-self: center !important;
	}

	.align-self-6k-baseline {
		-ms-flex-item-align: baseline !important;
		align-self: baseline !important;
	}

	.align-self-6k-stretch {
		-ms-flex-item-align: stretch !important;
		align-self: stretch !important;
	}
}

@media (min-width: 5700px) {
	.float-6k-left {
		float: left !important;
	}

	.float-6k-right {
		float: right !important;
	}

	.float-6k-none {
		float: none !important;
	}
}

@media (min-width: 5700px) {
	.m-6k-0 {
		margin: 0 !important;
	}

	.mt-6k-0,
	.my-6k-0 {
		margin-top: 0 !important;
	}

	.mr-6k-0,
	.mx-6k-0 {
		margin-right: 0 !important;
	}

	.mb-6k-0,
	.my-6k-0 {
		margin-bottom: 0 !important;
	}

	.ml-6k-0,
	.mx-6k-0 {
		margin-left: 0 !important;
	}

	.m-6k-1 {
		margin: 0.25rem !important;
	}

	.mt-6k-1,
	.my-6k-1 {
		margin-top: 0.25rem !important;
	}

	.mr-6k-1,
	.mx-6k-1 {
		margin-right: 0.25rem !important;
	}

	.mb-6k-1,
	.my-6k-1 {
		margin-bottom: 0.25rem !important;
	}

	.ml-6k-1,
	.mx-6k-1 {
		margin-left: 0.25rem !important;
	}

	.m-6k-2 {
		margin: 0.5rem !important;
	}

	.mt-6k-2,
	.my-6k-2 {
		margin-top: 0.5rem !important;
	}

	.mr-6k-2,
	.mx-6k-2 {
		margin-right: 0.5rem !important;
	}

	.mb-6k-2,
	.my-6k-2 {
		margin-bottom: 0.5rem !important;
	}

	.ml-6k-2,
	.mx-6k-2 {
		margin-left: 0.5rem !important;
	}

	.m-6k-3 {
		margin: 1rem !important;
	}

	.mt-6k-3,
	.my-6k-3 {
		margin-top: 1rem !important;
	}

	.mr-6k-3,
	.mx-6k-3 {
		margin-right: 1rem !important;
	}

	.mb-6k-3,
	.my-6k-3 {
		margin-bottom: 1rem !important;
	}

	.ml-6k-3,
	.mx-6k-3 {
		margin-left: 1rem !important;
	}

	.m-6k-4 {
		margin: 1.5rem !important;
	}

	.mt-6k-4,
	.my-6k-4 {
		margin-top: 1.5rem !important;
	}

	.mr-6k-4,
	.mx-6k-4 {
		margin-right: 1.5rem !important;
	}

	.mb-6k-4,
	.my-6k-4 {
		margin-bottom: 1.5rem !important;
	}

	.ml-6k-4,
	.mx-6k-4 {
		margin-left: 1.5rem !important;
	}

	.m-6k-5 {
		margin: 3rem !important;
	}

	.mt-6k-5,
	.my-6k-5 {
		margin-top: 3rem !important;
	}

	.mr-6k-5,
	.mx-6k-5 {
		margin-right: 3rem !important;
	}

	.mb-6k-5,
	.my-6k-5 {
		margin-bottom: 3rem !important;
	}

	.ml-6k-5,
	.mx-6k-5 {
		margin-left: 3rem !important;
	}

	.p-6k-0 {
		padding: 0 !important;
	}

	.pt-6k-0,
	.py-6k-0 {
		padding-top: 0 !important;
	}

	.pr-6k-0,
	.px-6k-0 {
		padding-right: 0 !important;
	}

	.pb-6k-0,
	.py-6k-0 {
		padding-bottom: 0 !important;
	}

	.pl-6k-0,
	.px-6k-0 {
		padding-left: 0 !important;
	}

	.p-6k-1 {
		padding: 0.25rem !important;
	}

	.pt-6k-1,
	.py-6k-1 {
		padding-top: 0.25rem !important;
	}

	.pr-6k-1,
	.px-6k-1 {
		padding-right: 0.25rem !important;
	}

	.pb-6k-1,
	.py-6k-1 {
		padding-bottom: 0.25rem !important;
	}

	.pl-6k-1,
	.px-6k-1 {
		padding-left: 0.25rem !important;
	}

	.p-6k-2 {
		padding: 0.5rem !important;
	}

	.pt-6k-2,
	.py-6k-2 {
		padding-top: 0.5rem !important;
	}

	.pr-6k-2,
	.px-6k-2 {
		padding-right: 0.5rem !important;
	}

	.pb-6k-2,
	.py-6k-2 {
		padding-bottom: 0.5rem !important;
	}

	.pl-6k-2,
	.px-6k-2 {
		padding-left: 0.5rem !important;
	}

	.p-6k-3 {
		padding: 1rem !important;
	}

	.pt-6k-3,
	.py-6k-3 {
		padding-top: 1rem !important;
	}

	.pr-6k-3,
	.px-6k-3 {
		padding-right: 1rem !important;
	}

	.pb-6k-3,
	.py-6k-3 {
		padding-bottom: 1rem !important;
	}

	.pl-6k-3,
	.px-6k-3 {
		padding-left: 1rem !important;
	}

	.p-6k-4 {
		padding: 1.5rem !important;
	}

	.pt-6k-4,
	.py-6k-4 {
		padding-top: 1.5rem !important;
	}

	.pr-6k-4,
	.px-6k-4 {
		padding-right: 1.5rem !important;
	}

	.pb-6k-4,
	.py-6k-4 {
		padding-bottom: 1.5rem !important;
	}

	.pl-6k-4,
	.px-6k-4 {
		padding-left: 1.5rem !important;
	}

	.p-6k-5 {
		padding: 3rem !important;
	}

	.pt-6k-5,
	.py-6k-5 {
		padding-top: 3rem !important;
	}

	.pr-6k-5,
	.px-6k-5 {
		padding-right: 3rem !important;
	}

	.pb-6k-5,
	.py-6k-5 {
		padding-bottom: 3rem !important;
	}

	.pl-6k-5,
	.px-6k-5 {
		padding-left: 3rem !important;
	}

	.m-6k-auto {
		margin: auto !important;
	}

	.mt-6k-auto,
	.my-6k-auto {
		margin-top: auto !important;
	}

	.mr-6k-auto,
	.mx-6k-auto {
		margin-right: auto !important;
	}

	.mb-6k-auto,
	.my-6k-auto {
		margin-bottom: auto !important;
	}

	.ml-6k-auto,
	.mx-6k-auto {
		margin-left: auto !important;
	}
}


@media (min-width: 5700px) {
	.text-6k-left {
		text-align: left !important;
	}

	.text-6k-right {
		text-align: right !important;
	}

	.text-6k-center {
		text-align: center !important;
	}
}

@media (min-width: 7600px) {
	.col-8k {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-ms-flex-positive: 1;
		flex-grow: 1;
		max-width: 100%;
	}

	.col-8k-auto {
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: none;
	}

	.col-8k-1 {
		-ms-flex: 0 0 8.333333%;
		flex: 0 0 8.333333%;
		max-width: 8.333333%;
	}

	.col-8k-2 {
		-ms-flex: 0 0 16.666667%;
		flex: 0 0 16.666667%;
		max-width: 16.666667%;
	}

	.col-8k-3 {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}

	.col-8k-4 {
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}

	.col-8k-5 {
		-ms-flex: 0 0 41.666667%;
		flex: 0 0 41.666667%;
		max-width: 41.666667%;
	}

	.col-8k-6 {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}

	.col-8k-7 {
		-ms-flex: 0 0 58.333333%;
		flex: 0 0 58.333333%;
		max-width: 58.333333%;
	}

	.col-8k-8 {
		-ms-flex: 0 0 66.666667%;
		flex: 0 0 66.666667%;
		max-width: 66.666667%;
	}

	.col-8k-9 {
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%;
	}

	.col-8k-10 {
		-ms-flex: 0 0 83.333333%;
		flex: 0 0 83.333333%;
		max-width: 83.333333%;
	}

	.col-8k-11 {
		-ms-flex: 0 0 91.666667%;
		flex: 0 0 91.666667%;
		max-width: 91.666667%;
	}

	.col-8k-12 {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}

	.order-8k-first {
		-ms-flex-order: -1;
		order: -1;
	}

	.order-8k-last {
		-ms-flex-order: 13;
		order: 13;
	}

	.order-8k-0 {
		-ms-flex-order: 0;
		order: 0;
	}

	.order-8k-1 {
		-ms-flex-order: 1;
		order: 1;
	}

	.order-8k-2 {
		-ms-flex-order: 2;
		order: 2;
	}

	.order-8k-3 {
		-ms-flex-order: 3;
		order: 3;
	}

	.order-8k-4 {
		-ms-flex-order: 4;
		order: 4;
	}

	.order-8k-5 {
		-ms-flex-order: 5;
		order: 5;
	}

	.order-8k-6 {
		-ms-flex-order: 6;
		order: 6;
	}

	.order-8k-7 {
		-ms-flex-order: 7;
		order: 7;
	}

	.order-8k-8 {
		-ms-flex-order: 8;
		order: 8;
	}

	.order-8k-9 {
		-ms-flex-order: 9;
		order: 9;
	}

	.order-8k-10 {
		-ms-flex-order: 10;
		order: 10;
	}

	.order-8k-11 {
		-ms-flex-order: 11;
		order: 11;
	}

	.order-8k-12 {
		-ms-flex-order: 12;
		order: 12;
	}

	.offset-8k-0 {
		margin-left: 0;
	}

	.offset-8k-1 {
		margin-left: 8.333333%;
	}

	.offset-8k-2 {
		margin-left: 16.666667%;
	}

	.offset-8k-3 {
		margin-left: 25%;
	}

	.offset-8k-4 {
		margin-left: 33.333333%;
	}

	.offset-8k-5 {
		margin-left: 41.666667%;
	}

	.offset-8k-6 {
		margin-left: 50%;
	}

	.offset-8k-7 {
		margin-left: 58.333333%;
	}

	.offset-8k-8 {
		margin-left: 66.666667%;
	}

	.offset-8k-9 {
		margin-left: 75%;
	}

	.offset-8k-10 {
		margin-left: 83.333333%;
	}

	.offset-8k-11 {
		margin-left: 91.666667%;
	}
}

@media (max-width: 7599.98px) {
	.table-responsive-8k {
		display: block;
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		-ms-overflow-style: -ms-autohiding-scrollbar;
	}

	.table-responsive-8k > .table-bordered {
		border: 0;
	}
}


@media (max-width: 7599.98px) {
	.navbar-expand-8k > .container,
	.navbar-expand-8k > .container-fluid {
		padding-right: 0;
		padding-left: 0;
	}
}


@media (min-width: 7600px) {
	.navbar-expand-8k {
		-ms-flex-flow: row nowrap;
		flex-flow: row nowrap;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.navbar-expand-8k .navbar-nav {
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.navbar-expand-8k .navbar-nav .dropdown-menu {
		position: absolute;
	}

	.navbar-expand-8k .navbar-nav .nav-link {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}

	.navbar-expand-8k > .container,
	.navbar-expand-8k > .container-fluid {
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.navbar-expand-8k .navbar-collapse {
		display: -ms-flexbox !important;
		display: flex !important;
		-ms-flex-preferred-size: auto;
		flex-basis: auto;
	}

	.navbar-expand-8k .navbar-toggler {
		display: none;
	}
}

@media (min-width: 7600px) {
	.d-8k-none {
		display: none !important;
	}

	.d-8k-inline {
		display: inline !important;
	}

	.d-8k-inline-block {
		display: inline-block !important;
	}

	.d-8k-block {
		display: block !important;
	}

	.d-8k-table {
		display: table !important;
	}

	.d-8k-table-row {
		display: table-row !important;
	}

	.d-8k-table-cell {
		display: table-cell !important;
	}

	.d-8k-flex {
		display: -ms-flexbox !important;
		display: flex !important;
	}

	.d-8k-inline-flex {
		display: -ms-inline-flexbox !important;
		display: inline-flex !important;
	}
}

@media (min-width: 7600px) {
	.flex-8k-row {
		-ms-flex-direction: row !important;
		flex-direction: row !important;
	}

	.flex-8k-column {
		-ms-flex-direction: column !important;
		flex-direction: column !important;
	}

	.flex-8k-row-reverse {
		-ms-flex-direction: row-reverse !important;
		flex-direction: row-reverse !important;
	}

	.flex-8k-column-reverse {
		-ms-flex-direction: column-reverse !important;
		flex-direction: column-reverse !important;
	}

	.flex-8k-wrap {
		-ms-flex-wrap: wrap !important;
		flex-wrap: wrap !important;
	}

	.flex-8k-nowrap {
		-ms-flex-wrap: nowrap !important;
		flex-wrap: nowrap !important;
	}

	.flex-8k-wrap-reverse {
		-ms-flex-wrap: wrap-reverse !important;
		flex-wrap: wrap-reverse !important;
	}

	.flex-8k-fill {
		-ms-flex: 1 1 auto !important;
		flex: 1 1 auto !important;
	}

	.flex-8k-grow-0 {
		-ms-flex-positive: 0 !important;
		flex-grow: 0 !important;
	}

	.flex-8k-grow-1 {
		-ms-flex-positive: 1 !important;
		flex-grow: 1 !important;
	}

	.flex-8k-shrink-0 {
		-ms-flex-negative: 0 !important;
		flex-shrink: 0 !important;
	}

	.flex-8k-shrink-1 {
		-ms-flex-negative: 1 !important;
		flex-shrink: 1 !important;
	}

	.justify-content-8k-start {
		-ms-flex-pack: start !important;
		justify-content: flex-start !important;
	}

	.justify-content-8k-end {
		-ms-flex-pack: end !important;
		justify-content: flex-end !important;
	}

	.justify-content-8k-center {
		-ms-flex-pack: center !important;
		justify-content: center !important;
	}

	.justify-content-8k-between {
		-ms-flex-pack: justify !important;
		justify-content: space-between !important;
	}

	.justify-content-8k-around {
		-ms-flex-pack: distribute !important;
		justify-content: space-around !important;
	}

	.align-items-8k-start {
		-ms-flex-align: start !important;
		align-items: flex-start !important;
	}

	.align-items-8k-end {
		-ms-flex-align: end !important;
		align-items: flex-end !important;
	}

	.align-items-8k-center {
		-ms-flex-align: center !important;
		align-items: center !important;
	}

	.align-items-8k-baseline {
		-ms-flex-align: baseline !important;
		align-items: baseline !important;
	}

	.align-items-8k-stretch {
		-ms-flex-align: stretch !important;
		align-items: stretch !important;
	}

	.align-content-8k-start {
		-ms-flex-line-pack: start !important;
		align-content: flex-start !important;
	}

	.align-content-8k-end {
		-ms-flex-line-pack: end !important;
		align-content: flex-end !important;
	}

	.align-content-8k-center {
		-ms-flex-line-pack: center !important;
		align-content: center !important;
	}

	.align-content-8k-between {
		-ms-flex-line-pack: justify !important;
		align-content: space-between !important;
	}

	.align-content-8k-around {
		-ms-flex-line-pack: distribute !important;
		align-content: space-around !important;
	}

	.align-content-8k-stretch {
		-ms-flex-line-pack: stretch !important;
		align-content: stretch !important;
	}

	.align-self-8k-auto {
		-ms-flex-item-align: auto !important;
		align-self: auto !important;
	}

	.align-self-8k-start {
		-ms-flex-item-align: start !important;
		align-self: flex-start !important;
	}

	.align-self-8k-end {
		-ms-flex-item-align: end !important;
		align-self: flex-end !important;
	}

	.align-self-8k-center {
		-ms-flex-item-align: center !important;
		align-self: center !important;
	}

	.align-self-8k-baseline {
		-ms-flex-item-align: baseline !important;
		align-self: baseline !important;
	}

	.align-self-8k-stretch {
		-ms-flex-item-align: stretch !important;
		align-self: stretch !important;
	}
}

@media (min-width: 7600px) {
	.float-8k-left {
		float: left !important;
	}

	.float-8k-right {
		float: right !important;
	}

	.float-8k-none {
		float: none !important;
	}
}

@media (min-width: 7600px) {
	.m-8k-0 {
		margin: 0 !important;
	}

	.mt-8k-0,
	.my-8k-0 {
		margin-top: 0 !important;
	}

	.mr-8k-0,
	.mx-8k-0 {
		margin-right: 0 !important;
	}

	.mb-8k-0,
	.my-8k-0 {
		margin-bottom: 0 !important;
	}

	.ml-8k-0,
	.mx-8k-0 {
		margin-left: 0 !important;
	}

	.m-8k-1 {
		margin: 0.25rem !important;
	}

	.mt-8k-1,
	.my-8k-1 {
		margin-top: 0.25rem !important;
	}

	.mr-8k-1,
	.mx-8k-1 {
		margin-right: 0.25rem !important;
	}

	.mb-8k-1,
	.my-8k-1 {
		margin-bottom: 0.25rem !important;
	}

	.ml-8k-1,
	.mx-8k-1 {
		margin-left: 0.25rem !important;
	}

	.m-8k-2 {
		margin: 0.5rem !important;
	}

	.mt-8k-2,
	.my-8k-2 {
		margin-top: 0.5rem !important;
	}

	.mr-8k-2,
	.mx-8k-2 {
		margin-right: 0.5rem !important;
	}

	.mb-8k-2,
	.my-8k-2 {
		margin-bottom: 0.5rem !important;
	}

	.ml-8k-2,
	.mx-8k-2 {
		margin-left: 0.5rem !important;
	}

	.m-8k-3 {
		margin: 1rem !important;
	}

	.mt-8k-3,
	.my-8k-3 {
		margin-top: 1rem !important;
	}

	.mr-8k-3,
	.mx-8k-3 {
		margin-right: 1rem !important;
	}

	.mb-8k-3,
	.my-8k-3 {
		margin-bottom: 1rem !important;
	}

	.ml-8k-3,
	.mx-8k-3 {
		margin-left: 1rem !important;
	}

	.m-8k-4 {
		margin: 1.5rem !important;
	}

	.mt-8k-4,
	.my-8k-4 {
		margin-top: 1.5rem !important;
	}

	.mr-8k-4,
	.mx-8k-4 {
		margin-right: 1.5rem !important;
	}

	.mb-8k-4,
	.my-8k-4 {
		margin-bottom: 1.5rem !important;
	}

	.ml-8k-4,
	.mx-8k-4 {
		margin-left: 1.5rem !important;
	}

	.m-8k-5 {
		margin: 3rem !important;
	}

	.mt-8k-5,
	.my-8k-5 {
		margin-top: 3rem !important;
	}

	.mr-8k-5,
	.mx-8k-5 {
		margin-right: 3rem !important;
	}

	.mb-8k-5,
	.my-8k-5 {
		margin-bottom: 3rem !important;
	}

	.ml-8k-5,
	.mx-8k-5 {
		margin-left: 3rem !important;
	}

	.p-8k-0 {
		padding: 0 !important;
	}

	.pt-8k-0,
	.py-8k-0 {
		padding-top: 0 !important;
	}

	.pr-8k-0,
	.px-8k-0 {
		padding-right: 0 !important;
	}

	.pb-8k-0,
	.py-8k-0 {
		padding-bottom: 0 !important;
	}

	.pl-8k-0,
	.px-8k-0 {
		padding-left: 0 !important;
	}

	.p-8k-1 {
		padding: 0.25rem !important;
	}

	.pt-8k-1,
	.py-8k-1 {
		padding-top: 0.25rem !important;
	}

	.pr-8k-1,
	.px-8k-1 {
		padding-right: 0.25rem !important;
	}

	.pb-8k-1,
	.py-8k-1 {
		padding-bottom: 0.25rem !important;
	}

	.pl-8k-1,
	.px-8k-1 {
		padding-left: 0.25rem !important;
	}

	.p-8k-2 {
		padding: 0.5rem !important;
	}

	.pt-8k-2,
	.py-8k-2 {
		padding-top: 0.5rem !important;
	}

	.pr-8k-2,
	.px-8k-2 {
		padding-right: 0.5rem !important;
	}

	.pb-8k-2,
	.py-8k-2 {
		padding-bottom: 0.5rem !important;
	}

	.pl-8k-2,
	.px-8k-2 {
		padding-left: 0.5rem !important;
	}

	.p-8k-3 {
		padding: 1rem !important;
	}

	.pt-8k-3,
	.py-8k-3 {
		padding-top: 1rem !important;
	}

	.pr-8k-3,
	.px-8k-3 {
		padding-right: 1rem !important;
	}

	.pb-8k-3,
	.py-8k-3 {
		padding-bottom: 1rem !important;
	}

	.pl-8k-3,
	.px-8k-3 {
		padding-left: 1rem !important;
	}

	.p-8k-4 {
		padding: 1.5rem !important;
	}

	.pt-8k-4,
	.py-8k-4 {
		padding-top: 1.5rem !important;
	}

	.pr-8k-4,
	.px-8k-4 {
		padding-right: 1.5rem !important;
	}

	.pb-8k-4,
	.py-8k-4 {
		padding-bottom: 1.5rem !important;
	}

	.pl-8k-4,
	.px-8k-4 {
		padding-left: 1.5rem !important;
	}

	.p-8k-5 {
		padding: 3rem !important;
	}

	.pt-8k-5,
	.py-8k-5 {
		padding-top: 3rem !important;
	}

	.pr-8k-5,
	.px-8k-5 {
		padding-right: 3rem !important;
	}

	.pb-8k-5,
	.py-8k-5 {
		padding-bottom: 3rem !important;
	}

	.pl-8k-5,
	.px-8k-5 {
		padding-left: 3rem !important;
	}

	.m-8k-auto {
		margin: auto !important;
	}

	.mt-8k-auto,
	.my-8k-auto {
		margin-top: auto !important;
	}

	.mr-8k-auto,
	.mx-8k-auto {
		margin-right: auto !important;
	}

	.mb-8k-auto,
	.my-8k-auto {
		margin-bottom: auto !important;
	}

	.ml-8k-auto,
	.mx-8k-auto {
		margin-left: auto !important;
	}
}


@media (min-width: 7600px) {
	.text-8k-left {
		text-align: left !important;
	}

	.text-8k-right {
		text-align: right !important;
	}

	.text-8k-center {
		text-align: center !important;
	}
}
