#dashboard {
    display:flex;
    flex-direction: column;
    align-content: center;
    height: 100vh;     
    max-width:100%;   
    position: relative; 
    /* background: linear-gradient(to bottom, #423081, #121212 200px);   */
    /* background: linear-gradient(to bottom, #423081, #121212fb 200px);   */
    background: linear-gradient(to bottom, #423081, #121212c0 12.5rem);  
}

@media (min-width: 1200px) {
    #dashboard{
        width: 68.75%;
    }
}

#player {
    margin-bottom: 2em;
}

#player {    
    /* border:1px solid #b2b2b2;; */
    /* background-color: #303030; */
    background-color: transparent;
}

#top{
    color:red;
    /* margin-top: 20px; */
    /* padding-top: 20px; */
    background-color: transparent;
    
}

#library {
    /* background: #121212;     */
    background: transparent;
    overflow-y: auto;
    flex-grow: 1;    
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
    /* z-index: -1; */
}

#inputPanel{
    background: transparent;
}

#dashboardVisualizer{
    opacity: 0.1;
}

@media (max-width: 1200px) {
    #dashboardVisualizer{
        opacity: 0.6;
    }
}

.dashboardBackButton{
    background:transparent;
    color: #b2b2b2;
    fill: #b2b2b2;
    border-color: #b2b2b2;
    /* margin-left: 12px; */
    /* margin-bottom: ; */
    border-width: 0.0625rem;
}

.dashboardBackButton:hover{
    background:transparent;
    border-color: white;
}

.sweetAlertItems{
    background: transparent;
    justify-content: left;
    text-align: left;
    color:white;
}